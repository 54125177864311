/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        
        $(document).foundation(); // Foundation JavaScript
      
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $('.tablepress td[colspan]').addClass('tablepress-colspan');

        $('.footer-menu .has-submenu > a').removeAttr('href');

      }
    },
    // Home page
    'home': {
      init: function() {},
      finalize: function() {}
    },
    'page_template_template_solutions': {
      init: function() {},
      finalize: function() {
        
        $('.team-slider').slick({
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: false,
          speed: 300,
          slidesToShow: 4,
          slidesToScroll: 4,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });

        $('.recent-transactions-slider').slick({
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: false,
          speed: 300,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });

        $('.news-slider').slick({
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: false,
          speed: 300,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });

      }

    },
    'single_available_property': {
      init: function() {},
      finalize: function() {
        
        $('.team-slider').slick({
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: false,
          speed: 300,
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });

        $('.photo-gallery-slider').slick({
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: false,
          speed: 300,
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      }
    },
    'single_people': {
      init: function() {},
      finalize: function() {

        $('.section-title-trigger').click(function(){
          $(this).next('.section-content').slideToggle(500);
          $(this).toggleClass('current');
        });
        
        $('.recent-transactions-slider').slick({
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: false,
          speed: 300,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });

        $('.news-slider').slick({
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: false,
          speed: 300,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      }
    },
    'post_type_archive_locations': {
      init: function() {},
      finalize: function() {      
        $('.state-name').click(function(){
          $(this).next('.state-locations').slideToggle(500);
          $(this).toggleClass('current');
        });
      }

    },
    'page_template_template_flexible': {
      init: function() {},
      finalize: function() {      
        
        $('.text-cycle-slider').slick({
          dots: true,
          arrows: false,
          infinite: true,
          speed: 800,
          slidesToShow: 1,
          slidesToScroll: 1,
          //fade: true,
          autoplay: true,
          autoplaySpeed: 2200
        });

        $('.card-cycle-slider').slick({
          //dots: true,
          arrows: true,
          infinite: true,
          speed: 300,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });

        $('.video-cycle-slider').slick({
          dots: true,
          arrows: true,
          infinite: true,
          speed: 1400,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          fade: true
        });

        $('.team-slider').slick({
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: false,
          speed: 300,
          slidesToShow: 4,
          slidesToScroll: 4,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

document.addEventListener('DOMContentLoaded', function() {
  var triggerLinks = document.querySelectorAll('.fc-trigger');
  triggerLinks.forEach(function(link) {
      link.addEventListener('click', function(event) {
          //event.preventDefault(); // Prevent default anchor behavior
          var hiddenDiv = document.querySelector('.fc-hidden');
          hiddenDiv.classList.toggle('fc-visible');
      });
  });
});

var dropdownMenus = document.getElementsByClassName('dropdown-menu-wrapper');
Array.from(dropdownMenus).forEach(function(dropdownMenu) {
  var anchor = dropdownMenu.getElementsByClassName('anchor')[0];
  // Toggle visibility when anchor is clicked
  anchor.addEventListener('click', function(evt) {
    evt.preventDefault(); // Prevent default action of anchor
    dropdownMenu.classList.toggle('visible');
  });
  // Close dropdown when clicking outside of it
  document.addEventListener('click', function(e) {
    if (!dropdownMenu.contains(e.target) && !anchor.contains(e.target)) {
        dropdownMenu.classList.remove('visible');
    }
  });
});

var checkLists = document.getElementsByClassName('Xdropdown-check-list');
Array.from(checkLists).forEach(function(checkList) {
  // Get the first 'anchor' within this checklist
  var anchor = checkList.getElementsByClassName('anchor')[0];
  if (anchor) {
    anchor.onclick = function(evt) {
      if (checkList.classList.contains('visible')) {
        checkList.classList.remove('visible');
      } else {
        checkList.classList.add('visible');
      }
    };
  }
});











/*
//var checkList = document.getElementById('list1');dropdown-check-list
var checkList = document.getElementsByClassName('dropdown-check-list');
checkList.getElementsByClassName('anchor')[0].onclick = function(evt) {
  if (checkList.classList.contains('visible'))
    checkList.classList.remove('visible');
  else
    checkList.classList.add('visible');
}

/*
const dropdownTrigger = document.querySelector('.dropdown-trigger');
const dropdownMenu = document.querySelector('.dropdown-menu');

dropdownTrigger.addEventListener('click', function() {
  dropdownMenu.classList.toggle('show'); // Toggle visibility using class
});

/*

$('.gallery').each(function( index ) {
  var gallery = $(this).attr('id');
  var lightbox = $('#' + gallery + ' a').simpleLightbox({ /* options });
});





$('.gallery').slick({
  dots: true,
  arrows: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
});

$('.gallery').each(function( index ) {
  var gallery = $(this).attr('id');
  var lightbox = $('#' + gallery + ' a').simpleLightbox({ /* options  });
});

// Simple Lightbox - Already Installed
var lightbox = $('.gallery-gallery a').simpleLightbox();

// Slick Slideshow - Already Installed
$('.home-slideshow').slick({
  infinite: true,
  cssEase: 'linear',
  autoplay: true,
  arrows: true,
  dots: true
});

// Smooth Scroll
var topOffset = 0;
$(".scroll-to, .scroll-to-li a").not('[href="#"]').not('[href="#0"]').click(function(t){if(location.pathname.replace(/^\//,"")===this.pathname.replace(/^\//,"")&&location.hostname===this.hostname){var e=$(this.hash);(e=e.length?e:$("[name="+this.hash.slice(1)+"]")).length&&(t.preventDefault(),$("html, body").animate({scrollTop:e.offset().top-topOffset},1e3,function(){var t=$(e);if(t.focus(),t.is(":focus"))return!1;t.attr("tabindex","-1"),t.focus()}))}});

*/
